import { defineStore } from 'pinia'
import axios from 'axios'
import { api } from '@/config'

export const useAuthUserStore = defineStore('authUser', {
  state: () => ({
    user: null,
    token: window.sessionStorage.getItem('token')
  }),

  getters: {
    isGuest: (state) => state.user === null,
    isUser: (state) => state.user !== null && state.user.role === 0,
    isAdmin: (state) => state.user !== null && state.user.role === 1,
    isTeacher: (state) => state.user !== null && state.user.role === 2
  },

  actions: {
    async login(login, password) {
      await axios
        .post(api.path('login'), { login, password })
        .then((res) => {
          this.user = res.data.user
          this.token = res.data.token
          window.sessionStorage.setItem("token", this.token);
        })
        .catch((err) => {
          this.user = null
          this.token = null        
          window.sessionStorage.removeItem('token')
          throw err
        })
    },

    async fetchUser() {
      try {
        const { data } = await axios.get(api.path('me'))
        this.user = data.user
      } catch (e) {
        this.user = null
        this.token = null        
        window.sessionStorage.removeItem('token')
      }
    },

    async logout() {
      await axios.post(api.path('logout'))
      this.user = null
      this.token = null
      window.sessionStorage.removeItem('token')
    }
  }
})
