import { defineStore } from 'pinia'
import axios from 'axios'
import { api } from '@/config'

export const useMessageStore = defineStore('message', {
  state: () => ({
    messages: []
  }),

  actions: {
    addMessage(message) {
      this.messages.push(message)
    },

    async fetchMessages(id) {
      const { data } = await axios.get(api.path('messages.index', { id }))
      this.messages = data
    }
  }
})
