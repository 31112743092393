<template>
  <v-app id="app">
    <!--
    <v-system-bar app color="black">
      <v-spacer></v-spacer>
      <span class="text-white">{{ state.dateNow }}</span>
    </v-system-bar>
    -->

    <app-header :showLoginButton="authUserStore.isGuest" :showLogoutButton="!authUserStore.isGuest" @logout="logout"> </app-header>
    <app-nav v-if="!authUserStore.isGuest" @logout="logout"></app-nav>

    <v-main>
      <v-container>
        <router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <div :key="route.name">
              <component :is="Component"></component>
            </div>
          </transition>
        </router-view>
      </v-container>
    </v-main>

    <v-footer app absolute class="font-weight-medium">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Bednarska Szkoła Realna</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref } from 'vue'

import { reactive } from 'vue'
import { onMounted } from 'vue'
import { onUnmounted } from 'vue'

import { useAuthUserStore } from '@/stores/auth-user'
import { useToast } from 'vue-toast-notification'
import { useRouter } from 'vue-router'

import AppHeader from '@/components/shared/AppHeader.vue'
import AppNav from '@/components/shared/AppNav.vue'

const authUserStore = useAuthUserStore()
const toast = useToast()
const router = useRouter()

async function logout() {
  await authUserStore.logout()

  toast.info('Zostałeś wylogowany z systemu')
  router.push('/login')
}

const state = reactive({ dateNow: new Date().toLocaleTimeString() })
let interval = ref()

function time() {
  state.dateNow = new Date().toLocaleTimeString()
}

onMounted(async () => {
  await router.isReady()
  interval.value = setInterval(time, 10000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>
