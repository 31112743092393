import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '@/components/App.vue'

import vuetify from '@/plugins/vuetify/vuetify'
import router from '@/plugins/router'
import store from '@/plugins/store'
import './bootstrap'

import ToastPlugin from 'vue-toast-notification'
//import "vue-toast-notification/dist/theme-default.css";
import 'vue-toast-notification/dist/theme-bootstrap.css'

import { defineRule, configure } from 'vee-validate'
import { required, min } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import pl from '@vee-validate/i18n/dist/locale/pl.json'

configure({
  generateMessage: localize({
    pl
  }),
  generateMessage: localize({
    pl: {
      names: {
        password: 'Hasło'
      }
    }
  })
})

defineRule('required', required)
defineRule('min', min)

defineRule('name', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  if (!/^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\s]+$/.test(value)) {
    return 'Błędne znaki w polu'
  }
  return true
})

defineRule('pesel', (value) => {
  if (!/^\d+$/.test(value)) {
    return 'W peselu są tylko cyfry'
  }
  return true
})

setLocale('pl')

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(store)
app.use(vuetify)
app.use(ToastPlugin, { duration: 5000 })

app.mount('#app')
