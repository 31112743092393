/**
 * auth.js
 */
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const SET_TOKEN = 'SET_TOKEN';

export const SET_MESSAGES = 'SET_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
