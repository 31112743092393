<template>
  <v-sheet border>
    <v-container>
<!--
      <v-overlay :model-value="loading" class="align-center justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
-->            

      <h3 class="text-h5 text-primary font-weight-bold">Egzaminy kandydata/tki</h3>

      <span v-if="!isExamPayment" class="text-subtitle-1 text-warning">Jeszcze nieopłacone</span>
      <span v-else class="text-subtitle-1 text-warning">Opłacone: {{ user.paid_at }}</span>

      <div v-if="user.facilities_1" class="mt-4">
        <v-alert v-if="user.facilities_longer_exam == '?'" density="compact" variant="outlined" type="info"
          >Prosba o wydłużony czas pisania egzaminów jest rozpatrywana.</v-alert
        >
        <v-alert v-if="user.facilities_longer_exam == 'T'" density="compact" variant="outlined" type="success"
          >Kandydat/ka <strong>ma wydłużony</strong> czas pisania egzaminów.</v-alert
        >
        <v-alert v-if="user.facilities_longer_exam == 'N'" density="compact" variant="outlined" type="error"
          >Kandydacie/tce <strong>nie przyznano</strong> wydłużonego czasu pisania egzaminów.</v-alert
        >
      </div>

      <div v-if="user.facilities_2" class="mt-4">
        <v-alert v-if="user.facilities_computer_exam == '?'" density="compact" variant="outlined" type="info">
          Prośba o możliwość pisania na komputerze egzaminu z języka polskiego jest rozpatrywana.
        </v-alert>
        <v-alert v-if="user.facilities_computer_exam == 'T'" density="compact" variant="outlined" type="success"
          >Kandydat/ka <strong>ma możliwość</strong> pisania na komputerze egzaminu z języka polskiego</v-alert
        >
        <v-alert v-if="user.facilities_computer_exam == 'N'" density="compact" variant="outlined" type="error"
          >Kandydacie/tce <strong>nie przyznano</strong> możliwości pisania na komputerze egzaminu z języka polskiego</v-alert
        >
      </div>

      <div v-if="!isExamPayment" class="mt-4">
        <v-alert variant="outlined" type="info" border="top">Terminy egzaminów zostaną przydzielone po wniesieniu opłaty egzaminacyjnej.</v-alert>
      </div>

      <div v-if="!user.parent_last_name" class="mt-4">
        <v-alert variant="outlined" type="info" border="top">Opłata jest możliwa dopiero po uzupełnieniu danych o szkole i rodzicu/opiekunie</v-alert>
      </div>

      <user-exams :user="user"></user-exams>

      <div class="text-body-1 text-info mt-4">
        <strong>Uwaga!</strong> Towarzystwo Przyjaciół I Społecznego Liceum Ogólnokształcącego jest organem prowadzącym Bednarską Szkołę Realną.
        Dlatego przelew opłaty za egzamin nastąpi na konto Towarzystwa.
      </div>

      <div class="mt-2">
        <pay-form ref="payFormRef"></pay-form>
        <v-btn :disabled="isPaymentDisabled || isExamPayment || !user.parent_last_name" variant="tonal" color="info" @click="pay()">
          zapłać online
          <v-img class="ml-3" :src="PayImage" width="70" height="25"></v-img>
        </v-btn>
      </div>
    </v-container>
  </v-sheet>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { api } from '@/config'

import PayForm from '@/components/ui/PayForm.vue'
import PayImage from '@/assets/Przelewy24_logo.png'

import UserExams from '@/components/shared/User/UserExams.vue'

import { settings } from '@/config'
const isPaymentDisabled = settings.isPaymentDisabled

import { useAuthUserStore } from '@/stores/auth-user'
const authUserStore = useAuthUserStore()
const user = authUserStore.user

const loading = ref(true)
const exams = ref([])
const isExamPayment = ref(false)

const payFormRef = ref(null)
function pay() {
  payFormRef.value.pay()
}

onMounted(async () => {
  loading.value = true
  isExamPayment.value = user.paid_at != null && user.paid_at !== ''
  const { data } = await axios.get(api.path('exams.index'))
  exams.value = data
  exams.value.sort((a, b) => (a.type_id > b.type_id ? 1 : b.type_id > a.type_id ? -1 : 0))
  loading.value = false
})

</script>
