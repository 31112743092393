<template>
  <v-row justify="center">
    <v-col cols="10" md="8" lg="5" xl="3">
      <v-sheet border v-if="applyState != 0 || true">
        <v-toolbar dark color="primary" flat>
          <v-toolbar-title>Logowanie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon="mdi-close" variant="plain" to="/"> </v-btn>
        </v-toolbar>
        <div class="pa-5">
          <v-form @submit.prevent="submit">
            <v-text-field
              v-model="login.value.value"
              label="login"
              variant="underlined"
              color="primary"
              prepend-icon="mdi-account"
              type="login"
              :error-messages="errors.login"
              :disabled="isSubmitting"
              data-test="login"
            ></v-text-field>

            <v-text-field
              v-model="password.value.value"
              label="hasło"
              variant="underlined"
              color="primary"
              prepend-icon="mdi-lock"
              :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="() => (passwordHidden = !passwordHidden)"
              :type="passwordHidden ? 'password' : 'text'"
              :error-messages="errors.password"
              :disabled="isSubmitting"
              data-test="password"
            ></v-text-field>

            <v-layout class="mt-4 mx-0">
              <v-spacer></v-spacer>
              <btn-text :loading="isSubmitting" :disabled="isSubmitting" :to="{ name: 'password-forgot' }">Nie pamiętasz hasła?</btn-text>
              <btn-submit :loading="isSubmitting" :disabled="isSubmitting || !meta.valid" class="ml-4">Zaloguj</btn-submit>
            </v-layout>
          </v-form>
        </div>
      </v-sheet>

      <div v-if="applyState == 1" class="text-black text-center mt-4">
        Nie masz jeszcze konta?
        <router-link class="pl-2 text-primary" :to="{ name: 'register' }"
          >Zarejestruj się</router-link
        >
      </div>
      <div class="text-center mt-2">
        <router-link class="text-primary" to="/">Powrót na stronę główną</router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import BtnSubmit from '@/components/shared/UI/BtnSubmit.vue';
import BtnText from '@/components/shared/UI/BtnText.vue';

import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'

import { settings } from '@/config'

import { useAuthUserStore } from '@/stores/auth-user'
import { useToast } from 'vue-toast-notification'
import { useRouter } from 'vue-router'

const authUserStore = useAuthUserStore()

const passwordHidden = ref(true)
const applyState = settings.applyState

const validationSchema = {
  login: 'required|min:5',
  password: 'required'
}

const { errors, handleSubmit, isSubmitting, meta } = useForm({
  validationSchema: validationSchema
})

const login = useField('login')
const password = useField('password')

const toast = useToast()
const router = useRouter()

const submit = handleSubmit(async (values) => {
  try {
    await authUserStore.login(values.login, values.password)
    toast.success('Jesteś zalogowany')

    if (authUserStore.isUser) {
      router.push({ name: 'user-dashboard' })
    } else if (authUserStore.isAdmin) {
      router.push({ name: 'admin-dashboard' })
    } else if (authUserStore.isTeacher) {
      router.push({ name: 'teacher-dashboard' })
    }
  } catch (error) {
    password.resetField()
  }
})

</script>
