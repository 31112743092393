<template>
  <v-sheet border>
    <v-container>
      <!--
      <v-overlay :model-value="loading" class="align-center justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
-->

      <div class="text-h5 text-primary font-weight-bold">Komunikator</div>
      <div class="text-subtitle-1 text-primary font-weight-normal">KORESPONDENCJA KANDYDATA/TKI I RODZICÓW/OPIEKUNÓW Z BEDNARSKĄ SZKOŁĄ REALNĄ.</div>

      <div class="text-primary text-subtitle-2 mt-2">
        W razie potrzeby nawiązania indywidualnego kontaktu, dotyczącego egzaminów, bardzo prosimy o ograniczenie się do wysyłania i odbierania
        korespondencji za pośrednictwem zamieszczonego niżej komunikatora. Postaramy się odpowiadać w ciągu jednego dnia roboczego.
      </div>

      <v-sheet id="scroll-target" max-height="400" class="overflow-y-auto mt-4" border>
        <v-container id="scrolled-content">
          <v-row v-for="m in messages" v-bind:key="m.id">
            <v-col v-if="m.self" cols="8">
              <v-sheet class="pa-2" color="grey-lighten-4" border>
                <pre style="white-space: pre-wrap">{{ m.text }}</pre>
              </v-sheet>
              <div class="text-caption">{{ getDate(m.created_at) }} {{ getTime(m.created_at) }}</div>
            </v-col>
            <v-col v-else cols="8" offset="4">
              <v-sheet class="pa-2" color="secondary" border>
                <pre style="white-space: pre-wrap">{{ m.text }}</pre>
              </v-sheet>
              <div class="text-caption">{{ getDate(m.created_at) }} {{ getTime(m.created_at) }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>

      <v-form @submit.prevent="submit" :disabled="isSubmitting">
        <div class="d-flex">
          <v-textarea
            class="ml-3"
            v-model="text.value.value"
            variant="underlined"
            color="primary"
            auto-grow
            clearable
            placeholder="Napisz wiadomość"
            rows="1"
            row-height="14"
            :error-messages="errors.text"
          ></v-textarea>

          <btn-submit class="ml-3 mt-4" :loading="isSubmitting" :disabled="isSubmitting || !meta.valid">
            <v-icon left>mdi-send</v-icon>
            Wyślij
          </btn-submit>
        </div>
      </v-form>
    </v-container>
  </v-sheet>
</template>

<script setup>
import BtnSubmit from '@/components/shared/UI/BtnSubmit.vue'

import { ref, computed, onMounted } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useToast } from 'vue-toast-notification'

import { useMessageStore } from '@/stores/message'
import axios from 'axios'
import { api } from '@/config'

const validationSchema = {
  text: 'required'
}

const { errors, setErrors, handleSubmit, isSubmitting, meta } = useForm({
  validationSchema: validationSchema
})

const text = useField('text')

const messageStore = useMessageStore()

const messages = computed(() => {
  return messageStore.messages
})

const submit = handleSubmit(async (values) => {
  const toast = useToast()
  await axios
    .post(api.path('messages.store'), values)
    .then((res) => {
      toast.success('Twoja wiadomość została wysłana')
      messageStore.addMessage(res.data)
      text.resetField()
    })
    .catch((err) => {
      setErrors(err.response.data.errors)
    })
    .then(() => {
      scrollTop()
    })
})

const container = ref(null)
const elem = ref(null)
const loading = ref(true)

function scrollTop() {
  elem.value = document.getElementById('scrolled-content')
  container.value = document.getElementById('scroll-target')
  container.value.scrollTop = Math.floor(elem.value.offsetHeight)
}

function getDate(dateTime) {
  const dt = new Date(dateTime)
  return dt.toLocaleDateString()
}

function getTime(dateTime) {
  const dt = new Date(dateTime)
  return dt.toLocaleTimeString()
}

onMounted(async () => {
  loading.value = true
  await messageStore.fetchMessages(0)
  loading.value = false
  scrollTop()
})
</script>
