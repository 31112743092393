<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <div class="text-caption font-weight-medium">Trudności kandydata/tki:</div>
    </v-col>
  </v-row>
  <v-row class="mt-0 pt-0">
    <v-col cols="4" class="mt-1" data-test="disabilities_1">
      <v-icon v-if="user.disabilities_1 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">dysgrafia</span>
    </v-col>
    <v-col cols="4" class="mt-1" data-test="disabilities_2">
      <v-icon v-if="user.disabilities_2 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">dysleksja</span>
    </v-col>
    <v-col cols="4" class="mt-1" data-test="disabilities_3">
      <v-icon v-if="user.disabilities_3 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">inne</span>
    </v-col>
  </v-row>

  <v-row class="mt-4">
    <v-col cols="12" class="pb-0 pt-0">
      <div class="text-caption font-weight-medium">Potrzeby egzaminacyjne kandydata/tki:</div>
    </v-col>
  </v-row>
  <v-row class="mt-0 pt-0">
    <v-col cols="4" class="mt-1" data-test="facilities_1">
      <v-icon v-if="user.facilities_1 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">przedłużony czas pisania egzaminów</span>
    </v-col>
    <v-col cols="4" class="mt-1" data-test="facilities_2">
      <v-icon v-if="user.facilities_2 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">pisanie egzaminu z języka polskiego na komputerze</span>
    </v-col>
    <v-col cols="4" class="mt-1" data-test="facilities_3">
      <v-icon v-if="user.facilities_3 == 0" icon="mdi-checkbox-blank-outline"></v-icon>
      <v-icon v-else icon="mdi-checkbox-marked-outline"></v-icon>
      <span class="ml-2">inne</span>
    </v-col>
  </v-row>

  <v-row class="">
    <v-col rows="12" class="pb-0 pt-0">
      <div class="text-caption font-weight-medium">Szczególne inne trudności bądź potrzeby egzaminacyjne:</div>
      <div class="ml-2">
        <pre class="text-subtitle-1 font-weight-medium" style="white-space: pre-wrap; line-height: 1em">{{ user.disabilities }}</pre>
      </div>
    </v-col>
  </v-row>

  <v-row class="mt-6">
    <v-col rows="12" class="pb-0 pt-0">
      <div class="text-caption font-weight-medium">Załączone dokumenty dotyczące dysfunkcji:</div>
      <div v-if="user.files.length == 0"><div class="ml-2 mb-2 text-subtitle-1 font-weight-medium">brak</div></div>
      <div v-else v-for="file in user.files" v-bind:key="file.id" class="my-0 py-0">
        <v-btn :href="href(file.id)" variant="text" prepend-icon="mdi-download" class="">
          <template v-slot:prepend>
            <v-icon color="primary"></v-icon>
          </template>
          <span class="text-subtitle-2 font-weight-medium">{{ file.name }}</span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
defineProps(['user'])

import { api } from '@/config'
import { useAuthUserStore } from '@/stores/auth-user'

const authUserStore = useAuthUserStore()

function href(id) {
  const token = authUserStore.token
  return `${api.path('profile.files', { id })}?token=${token}`
}
</script>
