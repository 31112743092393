import { createRouter, createWebHistory } from 'vue-router'
import { useAuthUserStore } from '@/stores/auth-user'

import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const authUserStore = useAuthUserStore()
  
  if (authUserStore.token) {
    await authUserStore.fetchUser()
  }
  
  const route = reroute(to)

  if (route) {
    next(route)
  } else {
    next()
  }
})

const rules = {
  guest_u: {
    fail: 'user-index',
    check: (authUserStore) => !(!authUserStore.isGuest && authUserStore.isUser)
  },
  guest_a: {
    fail: 'admin-index',
    check: (authUserStore) => !(!authUserStore.isGuest && authUserStore.isAdmin)
  },
  guest_t: {
    fail: 'teacher-index',
    check: (authUserStore) => !(!authUserStore.isGuest && authUserStore.isTeacher)
  },
  user_g: {
    fail: 'login',
    check: (authUserStore) => !(!authUserStore.isUser && authUserStore.isGuest)
  },
  user_a: {
    fail: 'admin-index',
    check: (authUserStore) => !(!authUserStore.isUser && authUserStore.isAdmin)
  },
  user_t: {
    fail: 'teacher-index',
    check: (authUserStore) => !(!authUserStore.isUser && authUserStore.isTeacher)
  },
  admin_g: {
    fail: 'login',
    check: (authUserStore) => !(!authUserStore.isAdmin && authUserStore.isGuest)
  },
  admin_u: {
    fail: 'user-index',
    check: (authUserStore) => !(!authUserStore.isAdmin && authUserStore.isUser)
  },
  admin_t: {
    fail: 'teacher-index',
    check: (authUserStore) => !(!authUserStore.isAdmin && authUserStore.isTeacher)
  },
  teacher_g: {
    fail: 'login',
    check: (authUserStore) => !(!authUserStore.isTeacher && authUserStore.isGuest)
  },
  teacher_u: {
    fail: 'user-index',
    check: (authUserStore) => !(!authUserStore.isTeacher && authUserStore.isUser)
  },
  teacher_a: {
    fail: 'admin-index',
    check: (authUserStore) => !(!authUserStore.isTeacher && authUserStore.isAdmin)
  }
}

function reroute(to) {
  let failRoute = false
  let checkResult = false

  const authUserStore = useAuthUserStore()

  to.meta.rules &&
    to.meta.rules.forEach((rule) => {
      let check = false
      if (Array.isArray(rule)) {
        const checks = []
        for (const i in rule) {
          checks[i] = rules[rule[i]].check(authUserStore)
          check = check || checks[i]
        }
        if (!check && !failRoute) {
          failRoute = rules[rule[checks.indexOf(false)]].fail
        }
      } else {
        check = rules[rule].check(authUserStore)
        if (!check && !failRoute) {
          failRoute = rules[rule].fail
        }
      }

      checkResult = checkResult && check
    })

  if (!checkResult && failRoute) {
    return { name: failRoute }
  }

  return false
}

export default router
