/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from "axios";
import { api } from "@/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
const state = () => ({
    messages: [],
});

/**
 * Mutations
 */
const mutations = {
    [types.SET_MESSAGES](state, messages) {
        state.messages = messages;
    },

    [types.ADD_MESSAGE](state, message) {
        state.messages.push(message);
    },
};

/**
 * Actions
 */
const actions = {
    async addMessage({ commit }, payload) {
        try {
            commit(types.ADD_MESSAGE, payload);
        } catch (e) {
            // commit(types.FETCH_USER_FAILURE)
        }
    },

    async fetchMessages({ commit }, id) {
        const { data } = await axios.get(api.path("messages.index", { id }));
        commit(types.SET_MESSAGES, data);
    },
};

/**
 * Getters
 */
const getters = {
    messages: (state) => state.messages,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
