<template>
  <v-sheet v-if="user.number>=100" border>
    <v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <h3 class="mb-4">Drogie Kandydatki, Drodzy Kandydaci, Szanowni Państwo,</h3>
      <div class="text-primary mt-2 mb-4">
        <div class="mb-2">Plik z informacjami o wynikach rekrutacji do Bednarskiej Szkoły Realnej można pobrać klikając niżej.</div>
        <btn-action :href="href()" target="_blank"><v-icon dark left>mdi-file-document-outline</v-icon>Wyniki rekrutacji</btn-action>
      </div>

      <v-dialog v-model="dialog" max-width="850px">
        <template v-slot:activator="{ props }">
          <div class="mb-2">
            Jeśli nie znalazłaś / nie znalazłeś się na liście przyjętych ani na liście rezerwowej, możesz uzyskać informację, jak Twoje wyniki
            egzaminacyjne wyglądają na tle wyników ogółu zdających - kliknij link poniżej.
          </div>
          <v-btn variant="flat" color="primary" rounded="0" v-bind="props"> Informacja o wynikach uzyskanych przez kandydata/tkę </v-btn>
        </template>

        <v-card>
          <v-card-text class="text-primary">
            <h3 class="mb-4">Drogie Kandydatki, Drodzy Kandydaci, Szanowni Państwo,</h3>
            <p>oto informacja o wynikach uzyskanych przez Ciebie, na tle wyników ogółu osób zdających do szkoły.</p>
            <p class="mt-2">
              Miara centylowa wskazuje, jaki procent osób uzyskał wynik niższy od Twojego. Dla każdej z części egzaminu wskazujemy jeden z czterech
              przedziałów centylowych, w którym się znalazłaś/znalazłeś.<br />
              Przedział D - od pierwszego do 40. centyla, przedział C - od 41. do 60., przedział B - od 61. do 80. i przedział A - od 81. do setnego
              centyla.
            </p>
            <p class="mt-2">
              Zgodnie z wcześniejszymi informacjami, nie przewidujemy przekazywania innych informacji zwrotnych na temat przebiegu egzaminów
              poszczególnych osób. Nie udostępniamy do wglądu prac pisemnych ani nie publikujemy treści naszych egzaminów. Przypominamy, że informację
              zwrotną otrzymują jedynie osoby, które nie zdały egzaminu. Jeśli jesteś na liście przyjętych lub na liście rezerwowej zamiast oznaczeń
              przedziałów zobaczysz znaki X.
            </p>
            <p class="mt-4">
              <b>Twoje wyniki mieszczą się w następujących przedziałach:</b><br />
              Zadanie zespołowe - przedział <b>{{ results[0].r1 }}</b
              ><br />
              Rozmowa kwalifikacyjna - przedział <b>{{ results[0].r4 }}</b
              ><br />
              Egzamin z matematyki - przedział <b>{{ results[0].r2 }}</b
              ><br />
              Egzamin z języka polskiego - przedział <b>{{ results[0].r3 }}</b>
            </p>
            <p class="mt-2">
              Pragniemy zaznaczyć, że przekazana przez nas informacja nie ma charakteru uniwersalnej diagnozy wiedzy i umiejętności, a w szczególności
              nie może być traktowana, jako wskazanie szans kandydatki/kandydata na innych egzaminach i podczas rekrutacji do innych szkół.<br />
              Sposób konstruowania egzaminów i przyjęte przez nas kryteria oceniania predyspozycji, umiejętności, wiedzy i motywacji kandydatek i
              kandydatów są ściśle dostosowane do specyficznych metod kształcenia w Szkole Realnej.
            </p>
            <p class="mt-2">
              Dziękujemy za udział w egzaminach.<br />
              Komisja Egzaminacyjna Bednarskiej Szkoły Realnej<br />
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <btn-cancel @click="dialog = false">Zamknij</btn-cancel>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div v-if="Number(counter) == 0" class="mb-0 pb-0">
        <div class="mt-4">Tutaj będziemy Państwa informować, której osobie z listy rezerwowej zaproponowaliśmy miejsce w szkole.</div>
        <div class="text-primary font-weight-bold">Jeszcze nikomu z listy rezerwowej nie zaproponowaliśmy miejsca w szkole.</div>
      </div>

      <div v-if="Number(counter) > 0" class="mb-0 pb-0">
        <div class="mt-4">
          Uprzejmie informujemy, że właśnie zaproponowaliśmy miejsce w szkole osobie znajdującej się na liście rezerwowej na miejscu:
        </div>
        <h2 class="ml-3 pb-0 text-primaryfont-weight-bold">{{ counter }}</h2>
      </div>
    </v-container>
  </v-sheet>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { api } from '@/config'
import { useAuthUserStore } from '@/stores/auth-user'
import { onMounted } from 'vue'

import BtnAction from '@/components/shared/UI/BtnAction.vue'
import BtnCancel from '@/components/shared/UI/BtnCancel.vue'

const loading = ref(false)

const counter = ref(0)
const results = ref([])
const dialog = ref(false)

const authUserStore = useAuthUserStore()
const user = authUserStore.user

function href() {
  const token = authUserStore.token
  const file = 'Wyniki rekrutacji do Bednarskiej Szkoły Realnej 2024.pdf'
  return `${api.path('files.user', { file })}?token=${token}`
}

onMounted(async () => {
  loading.value = true
  {
    const { data } = await axios.get(api.path('config.counter'))
    counter.value = data
  }
  {
    const { data } = await axios.get(api.path('results.index'))
    results.value = data
  }

  loading.value = false
})
</script>

