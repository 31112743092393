<template>
  <v-expansion-panels v-for="e in exams" v-bind:key="e.type" accordion multiple class="mt-4">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="text-subtitle-1 font-weight-medium">{{ e.type }}</v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-list density="compact">
          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-calendar-range</v-icon>
            </template>

            <v-list-item-subtitle>Data:</v-list-item-subtitle>
            <div v-if="e.type_id < 5">
              <strong>{{ getDate(e.date) }}</strong>
            </div>
            <div v-else><strong>* ???? *</strong></div>
          </v-list-item>

          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-map-marker</v-icon>
            </template>
            <v-list-item-subtitle>Miejsce:</v-list-item-subtitle>
            <div v-if="e.type_id == 1">
              <strong>{{ e.place }}</strong>
            </div>
            <div v-if="e.type_id == 2 || e.type_id == 3">
              <strong>{{ e.uplace }}</strong>
            </div>
            <div v-if="e.type_id == 4">
              <strong>{{ e.place }}</strong>
            </div>
          </v-list-item>

          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
            <v-list-item-subtitle>Godzina egzaminu</v-list-item-subtitle>
            <div v-if="e.type_id == 1 && e.time == '09:00:00'">
              <strong>Zgłoś się na egzamin między 8.45 a 9.00</strong>
            </div>
            <div v-if="e.type_id == 1 && e.time == '14:00:00'">
              <strong>Zgłoś się na egzamin między 13.45 a 14.00</strong>
            </div>
            <div v-if="e.type_id == 2 || e.type_id == 3">
              <strong>{{ getTime(e.time) }}</strong>
            </div>
            <div v-if="e.type_id == 4">
              <strong>{{ getTime(e.time) }}</strong>
            </div>
          </v-list-item>
        </v-list>
        <div v-if="authUserStore.isAdmin">
          <v-card-actions v-if="true && e.type_id == 1 && !show1">
            <v-btn outlined text color="primary" @click="changeExams1()">Zmień</v-btn>
          </v-card-actions>

          <v-card-actions v-if="e.type_id == 4 && !show4">
            <v-btn outlined text color="primary" @click="changeExams4()">Zmień</v-btn>
          </v-card-actions>

          <v-card-text class="pt-0 pb-0" v-if="e.type_id == 1 && show1">
            <v-card-title class="primary--text subtitle-2 pl-0 pt-0">Wybierz nowy termin egzaminu</v-card-title>
            <v-select
              v-model="examId1"
              :items="allExams1"
              :item-title="(item) => `${item.date_time}  ${item.place}  (${item.user_exams_count}/${item.max_users})`"
              item-value="id"
              variant="underlined"
              color="primary"
              label="Egzamin"
              dense
            ></v-select>
            <v-card-actions class="pl-0 pt-0">
              <v-btn class="pl-0" color="primary" text @click="doChangeTerm1()" :loading="loading">Zapisz zmiany</v-btn>
              <v-btn color="primary" text @click="show1 = false">Rezygnuj ze zmian</v-btn>
            </v-card-actions>
          </v-card-text>

          <v-card-text class="pt-0 pb-0" v-if="e.type_id == 4 && show4">
            <v-card-title class="primary--text subtitle-2 pl-0 pt-0 pb-0">Wybierz nowy termin egzaminu</v-card-title>
            <v-select
              v-model="examId"
              :items="allExams"
              :item-title="(item) => `${item.date_time}  ${item.place}  (${item.user_exams_count}/${item.max_users})`"
              item-value="id"
              label="Komisja"
              variant="underlined"
              color="primary"
              @update:modelValue="changeTerm4()"
              dense
            ></v-select>
            <v-select v-model="timeId" :items="freeHours" label="Godzina egzaminu" variant="underlined" color="primary" dense></v-select>
            <v-card-actions class="pl-0 pt-0">
              <v-btn class="pl-0" color="primary" text @click="doChangeTerm4()" :loading="loading" :disabled="timeHasValue()">Zapisz zmiany</v-btn>
              <v-btn color="primary" text @click="show4 = false">Rezygnuj ze zmian</v-btn>
            </v-card-actions>
          </v-card-text>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import axios from 'axios'
import { api } from '@/config'

import { useAuthUserStore } from '@/stores/auth-user'
const authUserStore = useAuthUserStore()

const props = defineProps(['user'])

const loading = ref(true)
const exams = ref([])

//Change exam.id = 1 (zespołowy)
const allExams1 = ref([])
const examId1 = ref(null)
const show1 = ref(false)

async function changeExams1() {
  const { data } = await axios.get(api.path('freeExams1'))
  allExams1.value = data.exams
  examId1.value = null
  show1.value = true
}

async function doChangeTerm1() {
  const toast = useToast()
  loading.value = true
  const postData = {
    user_id: props.user.id,
    exam_type: 1,
    exam_id: examId1.value
  }

  await axios
    .post(api.path('exams.update'), postData)
    .then(() => {
      toast.success('Zmiany zostały zapisane')
    })
    .catch(() => {})
    .then(() => {
      loading.value = false
    })

  const { id } = props.user
  const { data } = await axios.get(api.path('exams.user', { id }))
  exams.value = data

  show1.value = false
}

//Change exam.id = 4 (rozmowa)
const allExams = ref([])
const freeHours = ref([])
const examId = ref(null)
const timeId = ref(null)
const show4 = ref(false)

async function changeExams4() {
  const { data } = await axios.get(api.path('freeExams'))
  allExams.value = data.exams
  freeHours.value = []
  examId.value = null
  timeId.value = null
  show4.value = true
}

function timeHasValue() {
  return timeId.value == null || null // some comparison to determine its been selected
}

function changeTerm4() {
  timeId.value = null
  const i = allExams.value.findIndex((item) => item.id === examId.value)
  freeHours.value = []
  const time = new Date(allExams.value[i].date_time)
  for (let iUser = 0; iUser < allExams.value[i].max_users; iUser += 1) {
    const s = time.toLocaleTimeString()
    if (allExams.value[i].user_exams_count === 0 || !allExams.value[i].user_exams.find((item) => item.time === s)) {
      freeHours.value.push(s)
    }
    time.setMinutes(time.getMinutes() + allExams.value[i].interval)
  }
}

async function doChangeTerm4() {
  const toast = useToast()
  loading.value = true
  const postData = {
    user_id: props.user.id,
    exam_type: 4,
    exam_id: examId.value,
    time: timeId.value
  }
  await axios
    .post(api.path('exams.update'), postData)
    .then(() => {
      toast.success('Zmiany zostały zapisane')
    })
    .catch(() => {})
    .then(() => {
      loading.value = false
    })

  const { id } = props.user
  const { data } = await axios.get(api.path('exams.user', { id }))
  exams.value = data

  show4.value = false
}

//--------------------------------------------------
function getDate(dateTime) {
  return dateTime.substring(0, 10)
}

function getTime(time) {
  return time.substring(0, 5)
}

onMounted(async () => {
  let id = props.user.id
  loading.value = true
  const { data } = await axios.get(api.path('exams.user', { id }))
  exams.value = data
  exams.value.sort((a, b) => (a.type_id > b.type_id ? 1 : b.type_id > a.type_id ? -1 : 0))
  loading.value = false
})
</script>
