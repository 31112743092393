<template>
  <v-row class="mt-0">
    <v-col cols="4" class="">
      <div class="text-caption font-weight-light">Imię</div>
      <div class="text-h6 border-bottom">{{ user.first_name }}</div>
    </v-col>
    <v-col cols="4">
      <div class="text-caption font-weight-light">Nazwisko</div>
      <div class="text-h6 border-bottom">{{ user.last_name }}</div>
    </v-col>
    <v-col cols="4">
      <div class="text-caption font-weight-light">Kod egzaminacyjny</div>
      <div class="text-h6 border-bottom">{{ user.number }}</div>
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col cols="4">
      <div class="text-caption font-weight-light">Mail</div>
      <div class="text-subtitle-1 font-weight-medium border-bottom">{{ user.email }}</div>
    </v-col>
    <v-col cols="4">
      <div class="text-caption font-weight-light">Telefon</div>
      <div class="text-subtitle-1 font-weight-medium border-bottom">{{ user.phone_number ?? '---' }}</div>
    </v-col>
    <v-col cols="4">
      <div class="text-caption font-weight-light">Data urodzenia</div>
      <div class="text-subtitle-1 font-weight-medium border-bottom">{{ user.birth_date }}</div>
    </v-col>
  </v-row>

  <div v-if="authUserStore.isUser">
    <v-alert v-if="!user.parent_last_name" prominent dense type="error" class="mt-4">
      <v-row align="center">
        <v-col class="grow">Aby zakończyć rejestrację proszę uzupełnić brakujące dane wciskając przycisk edycji 'Uzupełnij dane'.</v-col>
        <v-col class="shrink">
          <v-btn :to="{ name: 'profile-edit' }">Uzupełnij dane</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script setup>
defineProps(['user'])

import { useAuthUserStore } from '@/stores/auth-user'
const authUserStore = useAuthUserStore()
</script>

<style scoped>
.border-bottom {
  border-bottom: solid 1px black;
}
</style>
