<template>
  <v-sheet border>
    <v-container>
      <h3 class="text-h5 text-primary font-weight-bold">Dane kandydata/tki</h3>

      <user-profile-main :user="user" />
      <div class="mt-2"><user-profile-school :user="user" /></div>
      <div class="mt-2"><user-profile-disabilities :user="user" /></div>
      <div class="mt-2"><user-profile-parents :user="user" /></div>
      <v-divider class="mt-4"></v-divider>

      <v-row class="mt-2">
        <v-col cols="auto">
          <btn-action :disabled="isEditDisabled" v-if="!user.parent_last_name" :to="{ name: 'profile-edit' }">Uzupełnij dane</btn-action>
          <btn-action :disabled="isEditDisabled" v-else :to="{ name: 'profile-edit' }">Zmień lub uzupełnij dane</btn-action>
        </v-col>
        <v-col cols="auto">
          <v-spacer></v-spacer>
          <btn-action  :to="{ name: 'password-edit' }">Zmień hasło</btn-action>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script setup>

import { settings } from '@/config'
const isEditDisabled = settings.isEditDisabled


import { useAuthUserStore } from '@/stores/auth-user'
const authUserStore = useAuthUserStore()
const user = authUserStore.user

import BtnAction from '@/components/shared/UI/BtnAction.vue'

import UserProfileMain from '@/components/shared/User/Profile/UserProfileMain.vue'
import UserProfileSchool from '@/components/shared/User/Profile/UserProfileSchool.vue'
import UserProfileDisabilities from '@/components/shared/User/Profile/UserProfileDisabilities.vue'
import UserProfileParents from '@/components/shared/User/Profile/UserProfileParents.vue'
</script>
