<template>
  <v-app-bar color="" app flat border="bottom">
    <div class="w-100 d-flex justify-space-between align-center">
      <v-img class="" :src="imageUrl" max-height="180" max-width="180"></v-img>

      <div class="text-h5 text-primary font-weight-bold">Internetowy system rekrutacji</div>

      <v-btn v-if="showLoginButton" prepend-icon="mdi-login" :disabled="applyState == 0" class="mx-4 text-primary" to="/login"> Zaloguj się </v-btn>

      <v-btn v-if="showLogoutButton" prepend-icon="mdi-logout" class="mr-4 text-primary" @click="$emit('logout')"> Zakończ </v-btn>
    </div>
  </v-app-bar>
</template>

<script setup>
import imageUrl from '@/assets/BSR-czb.png'

import { settings } from '@/config'
const applyState = settings.applyState

defineProps(['showLoginButton', 'showLogoutButton'])
</script>

<style scoped>
.border-bottom {
  border-bottom: solid 1px #616161;
}
</style>
