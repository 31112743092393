import axios from "axios";
import { api } from "@/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
const state = () => ({
    user: null,
    token: window.sessionStorage.getItem("token"),
});

/**
 * Mutations
 */
const mutations = {
    [types.SET_USER](state, { user }) {
        state.user = user;
    },

    [types.LOGOUT](state) {
        state.user = null;
        state.token = null;
        window.sessionStorage.removeItem("token");
    },

    [types.FETCH_USER_FAILURE](state) {
        state.user = null;
        window.sessionStorage.removeItem("token");
    },

    [types.SET_TOKEN](state, { token }) {
        state.token = token;
        window.sessionStorage.setItem("token", token);
    },
};

/**
 * Actions
 */
const actions = {
    saveToken({ commit }, payload) {
        commit(types.SET_TOKEN, payload);
    },

    async fetchUser({ commit }) {
        try {
            const { data } = await axios.get(api.path("me"));
            commit(types.SET_USER, data);
        } catch (e) {
            commit(types.FETCH_USER_FAILURE);
        }
    },

    setUser({ commit }, payload) {
        commit(types.SET_USER, payload);
    },

    async logout({ commit }) {
        await axios.post(api.path("logout"));
        commit(types.LOGOUT);
    },

    destroy({ commit }) {
        commit(types.LOGOUT);
    },
};

/**
 * Getters
 */
const getters = {
    user: (state) => state.user,
    token: (state) => state.token,
    isGuest: (state) => state.user === null,
    isUser: (state) => state.user !== null && state.user.role === 0,
    isAdmin: (state) => state.user !== null && state.user.role === 1,
    isTeacher: (state) => state.user !== null && state.user.role === 2,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
