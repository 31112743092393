import { createStore } from "vuex";

import auth from "./modules/auth";
import messages from "./modules/messages";

// Create a new store instance.
const store = createStore({
    modules: {
        auth,
        messages,
    },
});

/*
const requireContext = require.context("./modules", false, /.*\.js$/);

const modules = requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true;
        }

        return { ...modules, [name]: module };
    }, {});
*/

export default store;
